import styled from 'styled-components';

export const HomeContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 850px) {
    height: 100%;
  } 
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.sizes.margin};
  margin-top: 200px;
  @media (max-width: 768px) {
  margin-top: 30px;
    flex-direction: column;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: auto
  }
`;
