import React, { useCallback } from 'react';
import BackgroundImage from '../../components/BackgroundImage';
import Logo from '../../components/Logo';
import Card from '../../components/Card';
import { CardsContainer, HomeContainer, LogosContainer } from './styled';
import econestLogo from '../../assets/econest-logo.png';
import eagleWiseLogo from '../../assets/eagle-wise-logo.png';
import arrowIcon from '../../assets/arrow-icon.png';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback((link: string) => {
    navigate(link);
  }, []);

  return (
    <HomeContainer>
      <BackgroundImage />
      <LogosContainer>
        <Logo height="60px" padding="20px" src={econestLogo} alt="Left Logo" />
        <Logo padding="20px" src={eagleWiseLogo} alt="Right Logo" />
      </LogosContainer>
      <CardsContainer>
        <Card
          onClick={() => handleNavigate('/eco-saving')}
          icon={<img src={arrowIcon} alt="icon" />}
          title="EcoSaving"
          content="Scopri come migliorare le prestazioni energetiche del tuo immobile"
          buttonText="Scopri"
          color="rgba(15, 154, 0, 1)"
        />
        <Card
          onClick={() => handleNavigate('/rework-home')}
          icon={<img src={arrowIcon} alt="icon" />}
          color="rgba(215, 3, 3, 1)"
          title="ReWork Home"
          content="Per gli interventi di casa tua"
          buttonText="Scopri"
        />
        <Card
          onClick={() => handleNavigate('/real-estate-asset-analysis')}
          color="rgba(34, 78, 149, 1)"
          icon={<img src={arrowIcon} alt="icon" />}
          title="Real Estate Asset Analysis"
          content="Analisi del Patrimonio Immobiliare."
          buttonText="Scopri"
        />
        <Card
          onClick={() => handleNavigate('/salvacasa')}
          icon={<img src={arrowIcon} alt="icon" />}
          color="rgba(241, 106, 35, 1)"
          title="Salva Casa"
          content="Regolarizza il tuo immobile alla luce della nuova normativa vigente"
          buttonText="Scopri"
        />
      </CardsContainer>
    </HomeContainer>
  );
};

export default Home;
