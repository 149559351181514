import styled from 'styled-components';

export interface CustomTextProps {
  color?: string;
  size?: string;
  weight?: string;
  align?: string;
  text: string;
  margin?: string;
  as?: keyof JSX.IntrinsicElements;
  lineHeight?: string;
  isTitle?: boolean
  background?: string
}
type TextProps = Pick<CustomTextProps, 'color' | 'size' | 'weight' | 'align' | 'margin' | 'lineHeight' | 'isTitle' | 'background'>;

export const StyledText = styled.span<TextProps>`
  color: ${(props) => props.color || props.theme.colors.text};
  font-size: ${(props) => props.size || '16px'};
  font-weight: ${(props) => props.weight || 'normal'};
  text-align: ${(props) => props.align || 'left'};
  margin: ${(props) => props.margin || '0px'};
  line-height: ${(props) => props.lineHeight};
  text-wrap:'no-wrap';
  background-color: ${props => props.background};
  @media (max-width: 768px) {
    font-weight: ${(props) => props.weight || 'normal'};
    font-size: ${props => props.isTitle ? props.size : '16px'} 
  }
`;