import styled from 'styled-components';
import backgroundImage from '../../assets/primary-background.png';


export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;