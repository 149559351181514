import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width:80%;
    justify-content: center;
    align-items:center;
    margin: 100px auto;
    gap: 25px;

    @media (max-width: 768px){
        margin: 80px auto;
    }
`

export const LinkStyled = styled.a`
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    word-break: break-all;
`
export const TextRowContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 6px;
`

export const StyledSpan = styled.span`
font-size: 30px; font-weight: 200; text-wrap: 'no-wrap';
@media (max-width: 768px){
  font-size: 16px
}
`