import { useCallback } from 'react';
import Divider from '../components/Divider';
import IconButton from '../components/IconButton';
import Image from '../components/Image';
import Logo from '../components/Logo';
import Text from '../components/Text';
import {
  ButtonContainer,
  ColumnWrapper,
  ImageContainer,
  InfoTextContainer,
  StyledLink,
  StyledList,
  StyledListItem,
  TextContainer,
  Wrapper,
} from './styled';

import arrowIcon from '../../src/assets/arrow-icon.png';
import reworkBackground from '../../src/assets/rework-background.png';
import econestLogo from '../../src/assets/econest-logo.png';
import { useNavigate } from 'react-router-dom';

type Props = {
  link: string;
  name: string;
  color: string;
};

const ReWorkHome = () => {
  const navigate = useNavigate();

  const goToDetail = useCallback(
    ({ link, name, color }: Props) => {
      navigate(link, {
        state: {
          name,
          color,
        },
      });
    },
    [navigate]
  );
  return (
    <ColumnWrapper>
      <Wrapper>
        <TextContainer>
          <StyledLink to="/">
            <Logo alt="econest-logo" src={econestLogo} />
          </StyledLink>
          <Divider
            color="rgba(215, 3, 3, 1)"
            textColor="rgba(215, 3, 3, 1)"
            text="ReWork Home"
          />
          <Text
            margin="10px 10px 10px 0px"
            text="SIMULAZIONE COSTI INTERVENTI EDILIZI"
          />
          <Text
            margin="0px 10px 0px 0px"
            text=" Vuoi ristrutturare o hai in mente di effettuare un restyling degli ambiente di casa tua?
            Vuoi conoscere quanto costa realizzare il tuo progetto?"
          />
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(215, 3, 3, 1);
"
              size="15px"
              text="Siamo qui per aiutarti:"
            />
            <Text
              size="15px"
              text="il simulatore ReWork Home ti permetterà di
              ottenere un preventivo preliminare per gli interventi ipotizzati
              (edili, impiantistici, energetici, ecc)."
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(215, 3, 3, 1);
"
              size="15px"
              text="Come funziona:"
            />
            <Text
              size="15px"
              text="inserisci tutti i dati necessari. Al termine della
              simulazione, riceverai via email un report dettagliato."
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(215, 3, 3, 1);
"
              size="15px"
              text="Cosa riceverai:"
            />
            <Text size="15px" text="un report contenente:" />
          </InfoTextContainer>
          <StyledList color="rgba(215, 3, 3, 1)">
            <StyledListItem>
              L’elenco degli interventi ipotizzati (edili, impiantistici,
              energetici, ecc.) e la stima dei relativi costi
            </StyledListItem>
            <StyledListItem>
              La stima dei costi amministrativi e professionali
            </StyledListItem>
            <StyledListItem>
              I bonus fiscali a cui potresti avere diritto.
            </StyledListItem>
          </StyledList>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(215, 3, 3, 1);
"
              size="15px"
              text="Cosa fare:"
            />
            <Text
              size="15px"
              text="clicca sul pulsante INIZIA e avvia la simulazione!"
            />
          </InfoTextContainer>
        </TextContainer>
        <ImageContainer>
          <Image src={reworkBackground} alt="rework-home-image" />
        </ImageContainer>
      </Wrapper>
      <ButtonContainer>
        <IconButton
          onClick={() =>
            goToDetail({
              link: '/thank-you',
              name: 'ReWork Home',
              color: 'rgba(215, 3, 3, 1)',
            })
          }
          minWidth="14rem"
          backgroundColor="rgba(215, 3, 3, 1);"
          color="white"
          icon={<img src={arrowIcon} alt="icon" />}
          text="Inizia"
        />
      </ButtonContainer>
    </ColumnWrapper>
  );
};

export default ReWorkHome;
