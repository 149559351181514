import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Layout from './components/Layout';
import EcoSaving from './pages/EcoSaving';
import ReWorkHome from './pages/ReWorkHome';
import RealEstateAssetAnalysis from './pages/RealEstateAssetAnalysis';
import ThankYou from './pages/ThankYouPage/ThankYou';
import SalvaCasa from './pages/SalvaCasa';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/eco-saving" element={<EcoSaving />} />
          <Route path="/rework-home" element={<ReWorkHome />} />
          <Route
            path="/real-estate-asset-analysis"
            element={<RealEstateAssetAnalysis />}
          />
          <Route path="/salvacasa" element={<SalvaCasa />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
