import React, { PropsWithChildren } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../theme/theme';

const Container = styled.div`
  margin: 0 auto;
`;

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>{children}</Container>
    </ThemeProvider>
  );
};

export default Layout;
