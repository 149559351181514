import React from 'react';
import { StyledImage } from './styled';

type Props = {
  src: string;
  alt: string;
  size?: string;
  height?: string;
};

const Image = ({ src, alt, size, height }: Props) => {
  return (
    <StyledImage height={height} sizes={size} alt={alt} src={src}></StyledImage>
  );
};

export default Image;
