import styled from 'styled-components';
import { CardProps } from '.';


export const CardContainer = styled.div`
  background-color: #FFFFFFE5;
  padding: ${(props) => props.theme.sizes.padding};
  margin: ${(props) => props.theme.sizes.margin};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: center;
  max-width: 13rem;
  min-height: 21rem;
  position: relative;
  box-shadow: 12px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  width: 100%;
  gap: 8px;
  padding-top: 10px;
`
export const ButtonContainer = styled.div`
    position: absolute;
    bottom: 60px;
    right: -30px;
  @media (max-width: 768px) {
    bottom: 12px;
    right:0px;
    margin-top: 25px;
    display: flex;
    justify-content: center
  }

`