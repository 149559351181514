import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px 20px 0px 0px;
  padding-left: 20px;
  li::marker {
    color: ${props => props.color}
  }
`;

export const StyledListItem = styled.li`
  font-size: 15px;
  padding-bottom: 4px;
`;
export const LogoContainer = styled.div`
display: flex;
align-items:center;
justify-content: center;
`
export const Wrapper = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 850px) {
    flex-direction: column-reverse;
    margin: 0; 
  }
`;

export const TextContainer = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  @media (max-width: 850px) {
    width: auto;
    height: 100%;
    padding: 20px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (max-width: 850px) {
    overflow: hidden; 
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 850px) {
    width: 100vw; 
    height: 100vh; 
    object-fit: cover; 
    object-position: center; 
  }
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px 10px 0px;
`;

export const ColumnWrapper = styled.div`
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 850px) {
    max-width: 100%; 
    padding: 0; 
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const StyledLink = styled(Link)`
@media (max-width: 850px) {
    align-self: center;
    margin: '16px 0px 16px 0px';
  }
`
