import { useCallback } from 'react';
import Divider from '../components/Divider';
import IconButton from '../components/IconButton';
import Image from '../components/Image';
import Logo from '../components/Logo';
import Text from '../components/Text';
import {
  ButtonContainer,
  ColumnWrapper,
  ImageContainer,
  InfoTextContainer,
  StyledLink,
  StyledList,
  StyledListItem,
  TextContainer,
  Wrapper,
} from './styled';

import arrowIcon from '../../src/assets/arrow-icon.png';
import reaaBackground from '../../src/assets/real-estate-aa-background.png';
import econestLogo from '../../src/assets/econest-logo.png';
import { useNavigate } from 'react-router-dom';

type Props = {
  link: string;
  name: string;
  color: string;
};

const RealEstateAssetAnalysis = () => {
  const navigate = useNavigate();

  const goToDetail = useCallback(
    ({ link, name, color }: Props) => {
      navigate(link, {
        state: {
          name,
          color,
        },
      });
    },
    [navigate]
  );
  return (
    <ColumnWrapper>
      <Wrapper>
        <TextContainer>
          <StyledLink to="/">
            <Logo alt="econest-logo" src={econestLogo} />
          </StyledLink>
          <Divider
            color="rgba(34, 78, 149, 1)"
            textColor="rgba(34, 78, 149, 1)"
            text="Real Estate Asset Analysis"
          />
          <Text
            margin="10px 10px 10px 0px"
            text="ANALISI DEL PATRIMONIO IMMOBILIARE"
          />
          <Text
            margin="0px 10px 0px 0px"
            text="Vuoi conoscere quanto vale il patrimonio di tua proprietà? Vuoi avere una panoramica del contesto in cui è inserito?"
          />
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(34, 78, 149, 1);
"
              size="15px"
              text="Siamo qui per aiutarti:"
            />
            <Text
              size="15px"
              text="l’analisi ti permetterà di avere un quadro
              generale e di dettagli del contesto macroecomico e sociale e di
              conoscere come si inseriscono i beni sul mercato."
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(34, 78, 149, 1);
"
              size="15px"
              text="Come funziona:"
            />
            <Text
              size="15px"
              text="inserisci il tuo codice fiscale. Al termine della
              simulazione, riceverai via email un report dettagliato."
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(34, 78, 149, 1);
"
              size="15px"
              text="Cosa riceverai:"
            />
            <Text size="15px" text="un report contenente:" />
          </InfoTextContainer>
          <StyledList color="rgba(34, 78, 149, 1)">
            <StyledListItem>
              Individuazione del perimetro e consistenza immobiliare
            </StyledListItem>
            <StyledListItem>
              Valutazione di Mercato (MV) e della potenziale Redditività
            </StyledListItem>
            <StyledListItem>
              Analisi del contesto macroeconomico e sociale
            </StyledListItem>
          </StyledList>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(34, 78, 149, 1);
"
              size="15px"
              text="Cosa fare:"
            />
            <Text
              size="15px"
              text="clicca sul pulsante INIZIA e avvia la simulazione!"
            />
          </InfoTextContainer>
        </TextContainer>
        <ImageContainer>
          <Image src={reaaBackground} alt="reaa-image" />
        </ImageContainer>
      </Wrapper>
      <ButtonContainer>
        <IconButton
          onClick={() =>
            goToDetail({
              link: '/thank-you',
              name: 'Real Estate Asset Analysis',
              color: 'rgba(34, 78, 149, 1)',
            })
          }
          minWidth="14rem"
          backgroundColor="rgba(34, 78, 149, 1)"
          icon={<img src={arrowIcon} alt="icon" />}
          text="Inizia"
          color="white"
        />
      </ButtonContainer>
    </ColumnWrapper>
  );
};

export default RealEstateAssetAnalysis;
