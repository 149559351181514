import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#0070f3',
    secondary: '#1A1A1D',
    background: '#f5f5f5',
    text: '#333333',
  },
  sizes: {
    padding: '20px',
    margin: '16px',
    borderRadius: '4px',
  },
};

export { theme };
