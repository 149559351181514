import styled from 'styled-components';
import { IconButtonProps } from '.';

type ButtonProps = Pick<IconButtonProps, 'backgroundColor' | 'minWidth' | 'color'>;

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) => props.backgroundColor};
  color: black;
  border: none;
  font-size: 20px;
  padding: ${(props) => props.theme.sizes.padding};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  min-width: ${(props) => props.minWidth};
  position: relative;
  overflow: hidden; 
  box-shadow: 4px 4px 0px 0px rgba(157, 162, 193, 1);

`;

export const Text = styled.span`
  flex: 3;
  text-align: center;
  z-index: 1; 
  color: ${props => props.color || 'black'}
`;

export const IconWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  padding-left: 20px;
`;

export const Icon = styled.span`
  z-index: 1; 
  color: black;
  margin-right: 10px
`;
