import React, { ReactNode } from 'react';
import IconButton from '../IconButton';
import Text from '../Text';
import { ButtonContainer, CardContainer, TextContainer } from './styled';

export type CardProps = {
  title: string;
  content: string;
  buttonText: string;
  icon: ReactNode;
  color?: string;
  onClick: () => void;
};

const Card: React.FC<CardProps> = ({
  title,
  content,
  buttonText,
  icon,
  color,
  onClick,
}) => {
  return (
    <CardContainer>
      <TextContainer>
        <Text
          isTitle
          margin="0px 0px 0px 45px"
          color={color}
          as="h2"
          size="26px"
          align="right"
          text={title}
        />
        <Text
          text={content}
          margin="0px 0px 0px 34px"
          size="20px"
          align="right"
        />
      </TextContainer>
      <ButtonContainer>
        <IconButton
          onClick={onClick}
          minWidth="14rem"
          backgroundColor="rgba(205, 220, 57, 1)"
          icon={icon}
          text={buttonText}
        />
      </ButtonContainer>
    </CardContainer>
  );
};

export default Card;
