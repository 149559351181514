import React from 'react';
import { CustomTextProps, StyledText } from './styled';

const Text: React.FC<CustomTextProps> = ({
  color,
  size,
  weight,
  align,
  text,
  margin,
  as = 'span',
  lineHeight,
  isTitle,
  background,
}) => {
  return (
    <StyledText
      background={background}
      margin={margin}
      as={as}
      color={color}
      size={size}
      weight={weight}
      align={align}
      lineHeight={lineHeight}
      isTitle={isTitle}
    >
      {text}
    </StyledText>
  );
};

export default Text;
