import React, { PropsWithChildren } from 'react';
import Text from '../Text';
import { StyledDivider } from './styled';

type Props = PropsWithChildren<{
  color?: string;
  text: string;
  textColor?: string;
}>;

function Divider({ color, text, textColor }: Props) {
  return (
    <StyledDivider color={color}>
      <Text
        isTitle
        margin="10px 0px 10px 0px"
        color={textColor}
        as="h2"
        size="30px"
        align="left"
        text={text}
      />
    </StyledDivider>
  );
}

export default Divider;
