import React from 'react';
import { Button, IconWrapper, Icon, Text } from './styled';

export interface IconButtonProps {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  backgroundColor?: string;
  minWidth?: string;
  color?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  backgroundColor,
  icon,
  text,
  onClick,
  minWidth,
  color,
}) => {
  return (
    <Button
      minWidth={minWidth}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      <Text color={color}>{text.toLocaleUpperCase()}</Text>
      <IconWrapper>
        <Icon>{icon}</Icon>
      </IconWrapper>
    </Button>
  );
};

export default IconButton;
