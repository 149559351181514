import { useCallback } from 'react';
import Divider from '../components/Divider';
import IconButton from '../components/IconButton';
import Image from '../components/Image';
import Logo from '../components/Logo';
import Text from '../components/Text';
import {
  ButtonContainer,
  ColumnWrapper,
  ImageContainer,
  InfoTextContainer,
  StyledLink,
  StyledList,
  StyledListItem,
  TextContainer,
  Wrapper,
} from './styled';

import arrowIcon from '../../src/assets/arrow-icon.png';
import salvacasaBackground from '../../src/assets/salvacasa-background.png';
import econestLogo from '../../src/assets/econest-logo.png';
import { useNavigate } from 'react-router-dom';

type Props = {
  link: string;
  name: string;
  color: string;
};

const SalvaCasa = () => {
  const navigate = useNavigate();

  const goToDetail = useCallback(
    ({ link, name, color }: Props) => {
      navigate(link, {
        state: {
          name,
          color,
        },
      });
    },
    [navigate]
  );
  return (
    <ColumnWrapper>
      <Wrapper>
        <TextContainer>
          <StyledLink to="/">
            <Logo alt="econest-logo" src={econestLogo} />
          </StyledLink>
          <Divider
            color="rgba(241, 106, 35, 1)"
            textColor="rgba(241, 106, 35, 1)"
            text="Salva Casa"
          />
          <Text
            margin="10px 10px 10px 0px"
            text="REGOLARIZZARE IL TUO IMMOBILE"
          />
          <Text
            margin="0px 10px 0px 0px"
            text="La recente approvazione della Legge 105/2024 porta importanti novità nel settore edilizio, offrendo nuove possibilità per sanatorie, cambi di destinazione d'uso, e molto altro ancora."
          />
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(241, 106, 35, 1);
"
              size="15px"
              text="Principali novità introdotte dalla legge:"
            />
          </InfoTextContainer>
          <StyledList color="rgba(241, 106, 35, 1)">
          <StyledListItem>
              Abitabilità Rivista: nuove Mini Abitazioni e Mini Appartamenti
            </StyledListItem>
            <StyledListItem>
              Sanare le Piccole Irregolarità
            </StyledListItem>
            <StyledListItem>
              Stato Legittimo degli Immobili, nuove regole per la determinazione
            </StyledListItem>
            <StyledListItem>
              Eliminazione della Doppia Conformità
            </StyledListItem>
            <StyledListItem>
              Nuove Categorie di Interventi in Edilizia Libera
            </StyledListItem>
            <StyledListItem>
              Recupero del Sottotetto
            </StyledListItem>
            <StyledListItem>
              Cambio di Destinazione d'Uso
            </StyledListItem>
          </StyledList>
          <InfoTextContainer>
            <Text
              size="15px"
              text="Scopri come possiamo aiutarti a regolarizzare la tua situazione e migliorare il tuo immobile."
            />
          </InfoTextContainer>
        </TextContainer>
        <ImageContainer>
          <Image src={salvacasaBackground} alt="rework-home-image" />
        </ImageContainer>
      </Wrapper>
      <ButtonContainer>
        <IconButton
          onClick={() =>
            goToDetail({
              link: '/thank-you',
              name: 'Salva Casa',
              color: 'rgba(241, 106, 35, 1)',
            })
          }
          minWidth="14rem"
          backgroundColor="rgba(241, 106, 35, 1);"
          color="white"
          icon={<img src={arrowIcon} alt="icon" />}
          text="Inizia"
        />
      </ButtonContainer>
    </ColumnWrapper>
  );
};

export default SalvaCasa;
