import React from 'react';
import { LogoImage } from './styled';

export interface LogoProps {
  src: string;
  alt: string;
  padding?: string;
  height?: string;
}

const Logo: React.FC<LogoProps> = ({ src, alt, padding, height }) => {
  return <LogoImage height={height} padding={padding} src={src} alt={alt} />;
};

export default Logo;
