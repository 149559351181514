import React from 'react';
import Text from '../../components/Text';
import { LinkStyled, StyledSpan, TextRowContainer, Wrapper } from './styled';
import settingsIcon from '../../assets/settings.png';
import Image from '../../components/Image';
import { useLocation } from 'react-router-dom';
type Props = {
  color: string;
  name: string;
};

const ThankYou = () => {
  const location = useLocation();
  const { color, name } = location.state as Props;
  const handleClick = () => {
    const emailAddress = 'econest@eaglewise.it';
    const mailto = `mailto:${emailAddress}`;
    window.location.href = mailto;
  };

  return (
    <Wrapper>
      <Text
        align="center"
        size="60px"
        color={color}
        as="h1"
        text={name}
        weight="500"
      />
      <Text
        color="rgba(77, 77, 77, 1)"
        size="30px"
        as="h1"
        text="Il servizio è riservato ai clienti delle Banche Convenzionate"
        align="center"
      />
      <Image size="30px" height="30px" src={settingsIcon} alt="settings-icon" />
      <TextRowContainer>
        <LinkStyled onClick={handleClick}>
          <Text
            weight="300"
            color="rgba(77, 77, 77, 1)"
            size="30px"
            as="span"
            text="Scrivici "
            align="center"
          />
        </LinkStyled>
        <StyledSpan>per fissare un appuntamento...</StyledSpan>
      </TextRowContainer>
      <Text
        align="center"
        color="rgba(77, 77, 77, 1)"
        size="30px"
        weight="500"
        as="span"
        text="Eagle Engineering Srl"
        margin="0px 0px -25px 0px"
      />
      <Text
        align="center"
        color="rgba(77, 77, 77, 1)"
        size="30px"
        weight="300"
        as="span"
        text="Viale Sarca 222, 20126 - Milano"
        margin="0px 0px -25px 0px"
      />
      <TextRowContainer>
        <StyledSpan>Email: </StyledSpan>
        <LinkStyled onClick={handleClick}>
          <Text
            color="rgba(77, 77, 77, 1)"
            size="30px"
            weight="300"
            as="span"
            align="center"
            text="econest@eaglewise.it"
          />
        </LinkStyled>
      </TextRowContainer>
    </Wrapper>
  );
};

export default ThankYou;
