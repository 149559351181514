import { useCallback } from 'react';
import Logo from '../components/Logo';
import {
  ButtonContainer,
  ColumnWrapper,
  ImageContainer,
  InfoTextContainer,
  LogoContainer,
  StyledLink,
  StyledList,
  StyledListItem,
  TextContainer,
  Wrapper,
} from './styled';
import Image from '../components/Image';
import Divider from '../components/Divider';
import Text from '../components/Text';
import arrowIcon from '../assets/arrow-icon.png';
import ecoSaving from '../assets/ecosaving-image.png';
import econestLogo from '../assets/econest-logo.png';

import IconButton from '../components/IconButton';
import { useNavigate } from 'react-router-dom';

type Props = {
  link: string;
  name: string;
  color: string;
};

const EcoSaving = () => {
  const navigate = useNavigate();

  const goToDetail = useCallback(
    ({ link, name, color }: Props) => {
      navigate(link, {
        state: {
          name,
          color,
        },
      });
    },
    [navigate]
  );

  return (
    <ColumnWrapper>
      <Wrapper>
        <TextContainer>
          <StyledLink to="/">
            <Logo alt="econest-logo" src={econestLogo} />
          </StyledLink>
          <Divider
            color="rgba(15, 154, 0, 1)"
            textColor="rgba(15, 154, 0, 1)"
            text="EcoSaving"
          />
          <Text
            margin="10px 10px 10px 0px"
            text="SIMULAZIONE DI MIGLIORAMENTO ENERGETICO"
          />
          <Text
            margin="0px 10px 0px 0px"
            text="Hai mai sentito parlare delle normative green ma non sei sicuro se si
          applicano alla tua abitazione? Vuoi scoprire come ridurre i consumi
          energetici della tua casa?"
          />
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(15, 154, 0, 1)"
              size="15px"
              text="Siamo qui per aiutarti"
            />
            <Text
              size="15px"
              text="il simulatore EcoSaving ti permetterà di stimare quali interventi puoi effettuare per ridurre i consumi e migliorare la classe energetica della tua casa."
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(15, 154, 0, 1)"
              size="15px"
              text="Come funziona:"
            />
            <Text
              size="15px"
              text="inserisci tutti i dati necessari. Al termine della simulazione,
            riceverai via email un report dettagliato."
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(15, 154, 0, 1)"
              size="15px"
              text="Cosa riceverai:"
            />
            <Text size="15px" text="un report contenente:" />
          </InfoTextContainer>
          <StyledList color="rgba(15, 154, 0, 1)">
            <StyledListItem>
              I tuoi consumi annui attuali e una stima della tua classe
              energetica attuale
            </StyledListItem>
            <StyledListItem>
              Gli interventi migliorativi possibili per la tua casa, compresi i
              costi stimati
            </StyledListItem>
            <StyledListItem>
              La classe energetica che potresti raggiungere con questi
              interventi
            </StyledListItem>
            <StyledListItem>
              <StyledListItem>
                I bonus fiscali a cui potresti avere diritto.
              </StyledListItem>
            </StyledListItem>
          </StyledList>
          <InfoTextContainer>
            <Text
              weight="bold"
              color="rgba(15, 154, 0, 1)"
              size="15px"
              text="Cosa fare:"
            />
            <Text
              size="15px"
              text="clicca sul pulsante INIZIA e avvia la simulazione!"
            />
          </InfoTextContainer>
        </TextContainer>
        <ImageContainer>
          <Image src={ecoSaving} alt="eco-saving-image" />
        </ImageContainer>
      </Wrapper>
      <ButtonContainer>
        <IconButton
          onClick={() =>
            goToDetail({
              link: '/thank-you',
              name: 'EcoSaving',
              color: 'rgba(15, 154, 0, 1)',
            })
          }
          minWidth="14rem"
          backgroundColor="rgba(205, 220, 57, 1)"
          icon={<img src={arrowIcon} alt="icon" />}
          text="Inizia"
        />
      </ButtonContainer>
    </ColumnWrapper>
  );
};

export default EcoSaving;
